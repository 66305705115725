//
// general.scss
//

body {
  font-family: $font-family-secondary;
  overflow-x: hidden !important;
  font-size: $font-size-base;
  color: $body-color;
}
h1 {
  font-size: $font-size-h1;
}
h2 {
  font-size: $font-size-h2;
}
h3 {
  font-size: $font-size-h3;
}
h4 {
  font-size: $font-size-h4;
}
h5 {
  font-size: $font-size-h5;
}
h6 {
  font-size: $font-size-h6;
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: $font-family-base;
  line-height: 1.5;
  font-weight: 600;
}
::selection {
  background: lighten($primary, 5%);
  color: $white;
}

a {
  text-decoration: none !important;
}
p {
  line-height: 1.6;
}

mark {
  background-color: lighten($primary, 5%);
  color: $white;
}
