@import url("https://fonts.googleapis.com/css?family=Nunito:300,400,600,700&display=swap");
@import url("https://fonts.googleapis.com/css?family=Prompt:300&display=swap");

$white: #ffffff;
$primary: #eac14b;
$secondary: #caab96;
$success: #2eca8b;
$warning: #f17425;
$info: #17a2b8;
$danger: #e00a11;
$dark: #3c4858;
$black: #161c2d;
$muted: #8492a6;
$light: #f8f9fc;

$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;

$overlay: rgba(35, 37, 47, 0.7);
$gradident-overlay: rgba(47, 86, 212, 0.75);

$body-color: #161c2d;
$shadow: 0 0 3px rgba(0, 0, 0, 0.15);

$font-size-h1: 32px;
$font-size-h2: 26px;
$font-size-h3: 20px;
$font-size-h4: 18px;
$font-size-h5: 16px;
$font-size-h6: 14px;
$font-size-base: 15px;

$font-family-base: "Prompt", sans-serif;
$font-family-secondary: "Nunito", sans-serif;

$colors: (
  "primary": $primary,
  "secondary": $secondary,
  "success": $success,
  "warning": $warning,
  "info": $info,
  "danger": $danger,
  "dark": $dark,
  "muted": $muted,
  "light": $light,
);
