//
// features.scss
//
.feature-list {
  li {
    margin-bottom: 5px;
    font-size: 15px;
  }
}
.features {
  .icon {
    font-size: 24px;
    width: 50px;
    height: 50px;
    line-height: 50px;
    background: lighten($primary, 45%);
    text-align: center;
  }
  .image {
    img {
      height: 60px;
    }
    &:before {
      content: "";
      position: absolute;
      bottom: 0px;
      right: -15px;
      width: 70px;
      height: 70px;
      border-radius: 10px;
      transform: rotate(22deg);
      background-color: lighten($primary, 46%);
      z-index: -1;
    }
  }
  .title-2 {
    font-size: 20px;
  }
}
.key-feature {
  transition: all 0.2s ease;
  .icon {
    height: 40px;
    width: 40px;
    line-height: 40px;
    background: lighten($primary, 45%);
    font-size: 24px;
  }

  &:hover {
    transform: scale(1.05);
  }
}
.key-feature .content,
.features {
  .title {
    font-size: 18px;
  }
}
// Work process
.work-process {
  transition: all 0.5s ease;
  .title {
    font-size: 20px;
  }
  .step,
  .step-icon {
    font-size: 45px;
    opacity: 0.06;
  }
  a {
    font-size: 15px;
    &:hover {
      color: darken($primary, 10%) !important;
    }
  }
  &:hover {
    transform: translateY(-10px);
  }
}

//course.html
.course-feature {
  position: relative;
  z-index: 2;
  transition: all 0.5s ease;
  margin: -260px 0 0px;
  box-shadow: $shadow;
  .title {
    font-size: 20px;
    &:hover {
      color: $primary !important;
    }
  }
  .full-img {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    margin: 0 auto;
    z-index: -2;
    transition: all 0.5s ease;
  }
  &:hover {
    transform: translateY(-10px);
    .full-img {
      opacity: 0.08;
    }
  }
}

@media screen and (max-width: 767px) {
  .course-feature {
    margin: 0 0 30px;
  }
}
