/*********************************/
/*     INDEX         */
/*================================
 *   01.  Variables      *
 *   02.  Bootstrap Custom   *
 *   03.  Components       *
 *   04.  General        *
 *   05.  Helper         *
 *   06.  Menu         *
 *   07.  Home / Landing     *
 *   08.  Features / Services  *
 *   09.  Testimonial      *
 *   10.  Price        *
 *   11.  C.T.A        *
 *   12.  Blog / Careers / Job *
 *   13.  Work / Projects    *
 *   14.  Team         *
 *   15.  User Pages       *
 *   16.  Countdown      *
 *   17.  Contact        *
 *   18.  Footer         *
 ================================*/

@import "variables";
@import "bootstrap-custom";
@import "components";
@import "general";
@import "helper";
@import "menu";
@import "home";
@import "features";
@import "testi";
@import "price";
@import "cta";
@import "blog";
@import "work";
@import "team";
@import "user";
@import "countdown";
@import "contact";
@import "footer";

.Typist {
  display: inline;
}
li.active > a {
  color: #2f55d4 !important;
}

.main-slider > .slides {
  margin-left: 0 !important;
  padding-left: 0 !important;
}

.nav-link {
  border-radius: 10px !important;
}

.pagination .active a {
  color: #ffffff !important;
}

a {
  &:hover {
    cursor: pointer;
  }
}

.back-to-top {
  line-height: 31px !important;
}
.progress-bar {
  background-color: #2f55d4;
}

#mt-1 {
  margin-top: 5px !important;
}
#mb-1 {
  margin-top: 5px !important;
}

.nav-cta {
  vertical-align: middle;
  background: $primary;
}

@media screen and (max-width: 600px) {
  .banner {
    visibility: hidden;
    clear: both;
    float: left;
    display: none;
  }
  .nav-cta {
    height: 50px;
  }
}

@media screen and (min-width: 600px) {
  .banner-mobile {
    visibility: hidden;
    clear: both;
    float: left;
    display: none;
  }
  .nav-cta {
    height: 70px;
  }
}

.btn-line {
  font-size: 18px;
  background-color: green !important;
  color: white !important;
}

.react-player-wrapper {
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

h1 {
  font-size: 26px;
}

h3 {
    font-size: 20px;
}

p, button, .content, a, .btn {
    font-size: 18px;
}
