//
// footer.scss
//
.footer {
  background: $gray-100;
  padding: 60px 0;
  position: relative;
  color: $gray-700;
  font-size: 14px;
  .footer-head {
    font-size: 18px;
    letter-spacing: 1px;
    font-weight: 500;
  }
  .text-foot {
    color: $gray-600;
  }
  .footer-list {
    margin-bottom: 0;
    li {
      margin-bottom: 10px;
      a {
        transition: all 0.5s ease;
        &:hover {
          color: lighten($gray-500, 20%);
        }
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  &.footer-bar {
    padding: 30px 0;
    .payment-cards {
      img {
        height: 25px;
      }
    }
  }
}

hr {
  margin-top: 0;
  margin-bottom: 0;
  border: 0;
  border-top: 1px solid #333744;
}
